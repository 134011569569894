import { useEffect, useState } from 'react';

export function useFieldConfiguration(location) {
  const [fieldConfiguration, setFieldConfiguration] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const getParamValue = (param) => {
      return searchParams.get(param) || '';
    }

    const from = searchParams.get('from');
    if (from !== 'pay-sandbox') {
      // no field configuration
      setFieldConfiguration(null);
      return;
    }

    setFieldConfiguration({
      from: {
        value: from
      },
      fullName: {
        value: getParamValue('fullname'),
        canEdit: true
      },
      email: {
        value: getParamValue('email'),
        canEdit: false
      },
      phone: {
        value: getParamValue('phone'),
        canEdit: true
      },
      country: {
        value: getParamValue('country'),
        canEdit: false
      },
    });

  }, [location.search]);

  return fieldConfiguration;
}
