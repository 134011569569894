import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  InputText,
  Select,
  SelectOption,
  InputBase,
  Link,
  Caption,
  DateInput,
} from '@increasecard/typed-components';
import { FluidGrid } from '../../components/Grid';
import { CTAButton } from '../../components/CTAButton';
import { isWeekend, format, addBusinessDays } from 'date-fns';

const CONTACT_TIMES = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
];

function RecaptchaNotice() {
  return (
    <Caption>
      This site is protected by reCAPTCHA and the Google{' '}
      <Link href="https://policies.google.com/privacy">Privacy Policy</Link> and{' '}
      <Link href="https://policies.google.com/terms">Terms of Service</Link>{' '}
      apply.
    </Caption>
  );
}

export function EnterpriseContactForm({ onSubmit, dateDisplayFormat, fieldConfiguration }) {
  const { countries } = useStaticQuery(graphql`
    query {
      strapi {
        countries(sort: "name") {
          name
          codename
          IsoAlpha3
          unicodeFlagChar
          phoneCode
        }
      }
    }
  `).strapi;

  const getPreloadedValue = useCallback((fieldName) => {
    return fieldConfiguration && fieldName in fieldConfiguration ? fieldConfiguration[fieldName].value : '';
  }, [fieldConfiguration]);

  const canEdit = useCallback((fieldName) => {
    if (fieldConfiguration && fieldName in fieldConfiguration) {
      return fieldConfiguration[fieldName].canEdit;
    }
    // is not a fixed preloaded value, user can edit it
    return true;
  }, [fieldConfiguration]);

  const formOrigin = useMemo(() => {
    if (fieldConfiguration && 'from' in fieldConfiguration) {
      return fieldConfiguration['from'].value;
    }
    return 'landing';
  }, [fieldConfiguration]);

  const [date, setDate] = useState(
    format(addBusinessDays(new Date(), 3), dateDisplayFormat)
  );
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [country, setCountry] = useState();
  const [phone, setPhone] = useState();

  useEffect(() => {
    // update values when fieldConfiguration updates
    setEmail(getPreloadedValue('email'));
    setFullName(getPreloadedValue('fullName'));
    setCountry(getPreloadedValue('country'));
    setPhone(getPreloadedValue('phone'));
  }, [getPreloadedValue, fieldConfiguration])

  return (
    <FluidGrid
      as="form"
      id="IncreasePayFormDemo"
      style={{
        maxWidth: '600px',
        margin: '0 auto',
        gridRowGap: '24px',
        textAlign: 'initial',
      }}
      onSubmit={onSubmit}
    >
      <InputText
        className="grid-span-md-4"
        label="Nombre y Apellido"
        id="fullName"
        required
        value={fullName}
        disabled={!canEdit('fullName')}
        onChange={e=>setFullName(e.target.value)}
      />
      <InputText
        className="grid-span-md-4"
        label="Estoy interesado en"
        id="product"
        value="Pay"
        disabled
      />
      <input
        type="hidden"
        id="ticketReason"
        name="ticketReason"
        value="Pay"
      />
      <input
        type="hidden"
        id="origin"
        name="origin"
        value={formOrigin}
      />
      <InputBase
        className="grid-span-md-4"
        label="Dirección de correo laboral"
        type="email"
        id="workEmail"
        required
        value={email}
        disabled={!canEdit('email')}
        onChange={e=>setEmail(e.target.value)}
      />
      <InputBase
        className="grid-span-md-4"
        label="Sitio web de la Empresa"
        id="companyWebsite"
        pattern="(https?://)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$"
        placeholder="example.com"
        type="text"
      />
      <Select
        className="grid-span-md-4"
        label="País"
        id="country"
        name="country"
        required
        disabled={!canEdit('country')}
        value={country}
        onChange={e=>setCountry(e.target.value)}
      >
        <SelectOption disabled>Seleccione su país</SelectOption>
        {countries.map(({ name, codename }) => (
          <SelectOption key={codename} value={codename}>
            {name}
          </SelectOption>
        ))}
      </Select>
      <InputText
        className="grid-span-md-4"
        label="Teléfono con código de área"
        id="telephone"
        required
        value={phone}
        disabled={!canEdit('phone')}
        onChange={e=>setPhone(e.target.value)}
      />
      <DateInput
        className="grid-span-md-4"
        datepickerProps={{
          minDate: addBusinessDays(new Date(), 3),
          tileDisabled: ({ date }) => isWeekend(date),
        }}
        dateFormat={dateDisplayFormat}
        label="¿Cuándo quieres agendar el contacto?"
        onChange={v => setDate(format(v, dateDisplayFormat))}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        id="preferredCallingDate"
        value={date}
      />
      <Select
        className="grid-span-md-4"
        label="¿En qué horario?"
        id="preferredCallingTime"
        name="preferredCallingTime"
      >
        <SelectOption disabled>Seleccionar</SelectOption>
        {CONTACT_TIMES.map(value => (
          <SelectOption key={value} value={value}>
            {value}
          </SelectOption>
        ))}
      </Select>
      <CTAButton
        as="button"
        text="Enviar"
        type="submit"
        style={{ width: '100%' }}
      />
      <RecaptchaNotice />
    </FluidGrid>
  );
}
