import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { add, isBefore, parse, formatISO } from 'date-fns';
import { useLocation } from '@reach/router';
import { Paragraph as BaseParagraph, H2 } from '@increasecard/typed-components';
import { Layout } from '../../layout/Layout';
import { Container as BaseContainer } from '../../components/Container';
import { SuperHeader } from '../../components/Typography';
import { EnterpriseContactForm } from '../../pageSections/contacto/EnterpriseContactForm';
import SEO from '../../components/seo';
import { CTAButton } from '../../components/CTAButton';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFieldConfiguration } from '../../hooks/useFieldConfiguration';

const BASE_DOMAIN =
  process.env.GATSBY_STAGE === 'staging'
    ? 'staging.increase.app'
    : 'increase.app';

const DATE_DISPLAY_FORMAT = 'dd/MM/yyyy';

const Container = styled(BaseContainer)`
  padding-top: 24px;
  padding-bottom: 40px;
  max-width: 880px;
  text-align: center;
`;

const Title = styled(SuperHeader)`
  margin-bottom: 16px;
`;

const HeroHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Paragraph = styled(BaseParagraph)`
  margin-bottom: 9px;
`;

const SuccessTitle = styled.h1`
  font-size: 60px;
  line-height: 72px;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
`;

const SuccessParagraph = styled(H2).attrs({ as: 'p', weight: 'normal' })`
  margin-bottom: 8px;
`;

function validate(data) {
  const now = new Date();
  data.timeZoneOffset = now.getTimezoneOffset();

  const minTime = add(now, { hours: 24 });
  const requestTime = parse(
    data.preferredCallingDate,
    'yyyy-MM-dd',
    new Date()
  );
  const [hours, minutes] = data.preferredCallingTime
    .split(':')
    .map(s => parseInt(s));

  requestTime.setHours(hours, minutes);

  if (isBefore(requestTime, minTime)) {
    return ['El momento de contacto debe ser en al menos 48 horas desde ahora'];
  }
  return [];
}

async function buildForm({ form, location, executeRecaptcha }) {
  const searchParams = new URLSearchParams(location.search);
  const formData = new FormData(form);
  const data = {
    partner_key: searchParams.get('partner_key'),
    plan: searchParams.get('plan'),
    _reCaptchaToken: await executeRecaptcha('contacto/enterprise'),
  };
  for (const [key, value] of formData) {
    if (key === 'preferredCallingDate') {
      data[key] = formatISO(parse(value, DATE_DISPLAY_FORMAT, new Date()), {
        representation: 'date',
      });
      continue;
    }
    data[key] = value;
  }

  const errors = validate(data);
  if (errors.length > 0) {
    throw new Error(errors.join('\n'));
  }
  return data;
}

async function submitForm(data) {
  const response = await fetch(
    `https://partners-backend.${BASE_DOMAIN}/landing/contact`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.status !== 201) {
    const body = await response.json();
    throw new Error(JSON.stringify(body));
  }
}

function Form({ onSuccess, onError }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const location = useLocation();
  const fieldConfiguration = useFieldConfiguration(location);

  const enableAllFormElements = (form) => {
    if (!form || !form.elements) return;
    for (const element of form.elements) {
      element.disabled = false;
    }
    return form;
  }

  const handleSubmit = e => {
    e.preventDefault();
    buildForm({ form: enableAllFormElements(e.target), executeRecaptcha, location })
      .then(data => {
        submitForm(data)
          .then(() => onSuccess())
          .catch(onError);
      })
      .catch(onError);
  };

  return (
    <EnterpriseContactForm
      dateDisplayFormat={DATE_DISPLAY_FORMAT}
      onSubmit={handleSubmit}
      fieldConfiguration={fieldConfiguration}
    />
  );
}

export default ({ data }) => {
  const { title, subtitle, seoInfo } = data.strapi.enterpriseContactPage;
  const [formState, setFormState] = useState({ state: 'unsent' });
  return (
    <Layout>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      <Container>
        {formState.state === 'success' && (
          <>
            <SuccessTitle>
              El formulario ha sido enviado con éxito.
            </SuccessTitle>
            <SuccessParagraph>
              Gracias por enviarnos tu consulta.
            </SuccessParagraph>
            <SuccessParagraph>
              Nuestro equipo se pondrá en contacto contigo lo antes posible.
            </SuccessParagraph>
            <SuccessParagraph>
              Te invitamos a seguir navegando nuestro sitio web.
            </SuccessParagraph>
          </>
        )}
        {formState.state === 'unsent' && (
          <>
            <HeroHeader>
              <Title>{title}</Title>
              {subtitle.split('\n').map(p => (
                <Paragraph key={p}>{p}</Paragraph>
              ))}
            </HeroHeader>
            <Form
              onSuccess={() => setFormState({ state: 'success' })}
              onError={e =>
                setFormState({ state: 'error', payload: e.message })
              }
            />
          </>
        )}
        {formState.state === 'error' && (
          <>
            <SuccessTitle>Ha ocurrido un error</SuccessTitle>

            {process.env.NODE_ENV !== 'production' && (
              <SuccessParagraph>
                {formState.payload?.toString()}
              </SuccessParagraph>
            )}
            {formState.message && (
              <SuccessParagraph>{formState.message}</SuccessParagraph>
            )}

            <CTAButton
              as="button"
              text="Reintentar"
              onClick={() => setFormState({ state: 'unsent' })}
            />
          </>
        )}
      </Container>
    </Layout>
  );
};
export const query = graphql`
  query {
    strapi {
      enterpriseContactPage {
        seoInfo {
          pageTitle
          pageDescription
          keywords
        }
        title
        subtitle
      }
    }
  }
`;
